import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit, Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-dialog-ask-remove-order',
  templateUrl: './dialog-ask-remove-order.component.html',
  styleUrls: ['./dialog-ask-remove-order.component.css']
})
export class DialogAskRemoveOrderComponent implements OnInit {
  orderNumber:string;

  constructor(
    public orders:OrdersService, 
    private router:Router,
    @Inject(MAT_DIALOG_DATA) public data:any
   ) {}


 
  removeOrder(){
    this.orderNumber= this.orders.customerOrderForm.get(['order_number']).value;
    console.log('Order Re-Moved OK ' + this.orderNumber);
    this.orders.removeOrder(this.orderNumber).subscribe(res => console.log('Removing Order: ' + this.orderNumber));
    this.router.navigate(['/']);
  }

  ngOnInit(): void {



  }

}
