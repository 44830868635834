<body>
    <div class="data-container">
        <div *ngFor="let order of dataSource" style="color: grey;">
             ({{order.quantity_01}} + {{order.quantity_02}} ) ${{order.total}} {{order.order_number}} 
        </div>
    </div>

    <div class="container-form">
        <form [formGroup]="orders.customerFormBuilder">
            <div class="data-container">
                <h1>{{customerType}} Customer:<br>{{orders.customerFormBuilder.get(['main_recipient']).value}} <br> {{orders.customerFormBuilder.get(['phone']).value}} </h1>
                <div class="customer-part-a">

                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Customer ID:</mat-label>
                        <input matInput disabled value="{{orders.customerFormBuilder.get(['customer_id']).value}}">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Phone Number:</mat-label>
                        <input matInput disabled value="{{orders.customerFormBuilder.get(['phone']).value}}">
                    </mat-form-field> -->
                    
                   <mat-form-field appearance="outline">
                    <mat-label>Business Name:</mat-label>
                    <input matInput  type="text" formControlName="biz_name">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Customer Full Name:</mat-label>
                        <input matInput  type="text" formControlName="main_recipient" autofocus>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                        <mat-label>Email:</mat-label>
                        <input matInput  type="text" formControlName="email">
                    </mat-form-field>
                   
                    <mat-form-field appearance="outline">
                        <mat-label>Second Recipient Name:</mat-label>
                        <input matInput  type="text" formControlName="secn_recipient">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Second Phone:</mat-label>
                        <input matInput  type="text" formControlName="phone2">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Address Type:</mat-label>
                        <input matInput  type="text" formControlName="address_type">
                    </mat-form-field>                        
                                        
                    <mat-form-field appearance="outline">
                        <mat-label>Address:</mat-label>
                        <input matInput  type="text" formControlName="address">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Address Detail:</mat-label>
                        <input matInput  type="text" formControlName="address_detail">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>City:</mat-label>
                        <input matInput  type="text" formControlName="city">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>State:</mat-label>
                        <input matInput  type="text" maxlength="2" formControlName="state">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Zip Code:</mat-label>
                        <input matInput  type="text"  maxlength="10" formControlName="zip_code">
                    </mat-form-field>                    
                                            
                    <mat-form-field appearance="outline">
                        <mat-label>Payment Type:</mat-label>
                        <input matInput  type="text"  formControlName="payment_type">
                    </mat-form-field>
                    
                    <div>
                        <mat-form-field appearance="outline">
                            <mat-label>Credit Card Number:</mat-label>
                            <input matInput  type="text" maxlength="16" formControlName="cc_numbers">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline">
                            <mat-label>CC Exp:</mat-label>
                            <input matInput  type="text" maxlength="4" formControlName="cc_exp">
                        </mat-form-field>
                        
                        <mat-form-field appearance="outline">
                            <mat-label>CC CVC Code:</mat-label>
                            <input matInput  type="text" maxlength="4" formControlName="cvc_code">
                        </mat-form-field>    
                    </div>
                </div>
                <div>
                    <div>
                        <form>
                            <mat-form-field appearance="outline">
                                <mat-label for="">Add New Card Number:</mat-label>
                                <input matInput #newnumber name="newnumber" type="text" maxlength="19" [placeholder]="tempCardNumber">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label for="">Exp:</mat-label>
                                <input matInput #newexp type="text" maxlength="4" [placeholder]="tempCardExp">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label for="">CVC:</mat-label >
                                <input matInput #newcvc type="text" maxlength="4" [placeholder]="tempCardCvc">
                            </mat-form-field>
                            <button mat-button (click)="addNewCard(newnumber,newexp,newcvc)"  class="card-button" type="button">+<mat-icon>credit_card</mat-icon></button>
                        </form>
                    </div>
                    <!-- <h2>
                        {{orders.customerFormBuilder.get(['cc_numbers']).value}}
                        {{orders.customerFormBuilder.get(['cc_exp']).value}}
                        {{orders.customerFormBuilder.get(['cvc_code']).value}}
                    </h2> -->
                    <div *ngFor="let card of cards; index as i">
                        <h2>
                            {{card.cardNumber}} {{card.exp}} {{card.cvc}}
                            <button mat-button  class="card-button" type="button" (click)="removeCard(i)"><mat-icon>delete</mat-icon></button>
                        </h2>                            
                    </div>
                </div>
                <div>
                    <button mat-raised-button matBadge="1" matBadgePosition="before" matBadgeColor="accent" color="primary" [disabled]="!orders.customerFormBuilder.valid" (click)="saveCustomer()">Save<mat-icon>sync</mat-icon></button>
                </div>             
            </div>   
        </form>
    
    </div>
    
    
    <div class="container-form">
        <form [formGroup]="orders.customerOrderForm">
            <div class="data-container">
                <div class="customer-part-a">
                    <h1>NEW ORDER NUMBER: {{orders.customerOrderForm.get(['order_number']).value}} </h1>
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Total:</mat-label>
                        <input matInput  type="text" formControlName="total">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Payment Status:</mat-label>
                        <input matInput  type="text" formControlName="payment_status">
                    </mat-form-field>     
                    <br>
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Quantity:</mat-label>
                        <input matInput  type="number"  formControlName="quantity_01">
                    </mat-form-field>

                    <mat-form-field appearance="outline" >
                        <mat-label>Ordeno:</mat-label>
                        <input matInput  type="text"  formControlName="product01_id">
                    </mat-form-field>
                  
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Quantity:</mat-label>
                        <input matInput  type="number"  formControlName="quantity_02">
                    </mat-form-field>

                    <mat-form-field appearance="outline" >
                        <mat-label>Ordeno:</mat-label>
                        <input matInput  type="text"  formControlName="product02_id">
                    </mat-form-field>
                   
                    <br>
    
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>MSRP Price:</mat-label>
                        <input matInput  type="number" disabled value="{{orders.customerOrderForm.get(['total']).value  * 2}}">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Total Discount:</mat-label>
                        <input matInput  type="number" disabled value="{{orders.customerOrderForm.get(['total']).value}}">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline" class="small-size">
                        <mat-label>Shipping Rate:</mat-label>
                        <input matInput  type="number" disabled value="{{orders.customerOrderForm.get(['total_shipping']).value}}">
                    </mat-form-field>
                    
                    <mat-form-field appearance="outline">
                        <mat-label>Order Date:</mat-label>
                        <input matInput [matDatepicker]="orderDate" formControlName="order_date" >
                        <mat-datepicker-toggle matSuffix [for]="orderDate"></mat-datepicker-toggle>
                        <mat-datepicker #orderDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Shipping Date:</mat-label>
                        <input matInput [matDatepicker]="shippingDate" formControlName="shipping_date">
                        <mat-datepicker-toggle matSuffix [for]="shippingDate"></mat-datepicker-toggle>
                        <mat-datepicker #shippingDate></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Delivery Date:</mat-label>
                        <input matInput [matDatepicker]="deliveryDate"  formControlName="delivery_date">
                        <mat-datepicker-toggle matSuffix [for]="deliveryDate"></mat-datepicker-toggle>
                        <mat-datepicker #deliveryDate></mat-datepicker>
                    </mat-form-field>
                    <br>
                    <!-- <mat-form-field appearance="outline">
                        <mat-label>Delivery Type:</mat-label>
                        <input matInput  type="text" formControlName="delivery_type">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Status:</mat-label>
                        <input matInput  type="text" formControlName="delivery_status">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Notes:</mat-label>
                        <input matInput  type="text" formControlName="status_note">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Tracking Number:</mat-label>
                        <input matInput  type="text" formControlName="tracking_number">
                    </mat-form-field> -->
                </div>       
    
                <div class="buttons-groups">
                    <button type="button"  mat-raised-button color="primary" [disabled]="!orders.customerFormBuilder.valid" (click)="saveNewOrder()" >Add New Order</button>
                </div>
    
    
            </div>   
        </form>
    
    </div>
    <div class="data-container"></div>    
</body>