
<div class="new-customer-form">
    <h1>ADD NEW CUSTOMER</h1>
    <form [formGroup]="orders.customerFormBuilder">
        <div class="data-container">
            <div class="customer-part-a">
                <mat-form-field>
                    <mat-label>Customer ID:</mat-label>
                    <input matInput disabled value="{{orders.customerFormBuilder.get(['customer_id']).value}}"  >
                </mat-form-field>
                
                <mat-form-field>
                    <mat-label>Phone Number:</mat-label>
                    <input matInput disabled value="{{orders.customerFormBuilder.get(['phone']).value}}"  >
                </mat-form-field>
                
               <mat-form-field color="accent">
                <mat-label>Business Name:</mat-label>
                <input matInput type="text" formControlName="biz_name">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Customer Full Name:</mat-label>
                    <input matInput type="text" formControlName="main_recipient" autofocus>
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Second Recipient Name:</mat-label>
                    <input matInput type="text" formControlName="secn_recipient">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Second Phone:</mat-label>
                    <input matInput type="text" formControlName="phone2">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Email:</mat-label>
                    <input matInput type="text" formControlName="email">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Address Type:</mat-label>
                    <input matInput type="text" formControlName="address_type">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Address:</mat-label>
                    <input matInput type="text" formControlName="address">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Address Detail:</mat-label>
                    <input matInput type="text" formControlName="address_detail">
                </mat-form-field>
                
                </div>
               <div class="customer-part-b">
                <mat-form-field color="accent">
                    <mat-label>City:</mat-label>
                    <input matInput type="text" formControlName="city">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>State:</mat-label>
                    <input matInput type="text" maxlength="2" formControlName="state">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Zip Code:</mat-label>
                    <input matInput type="text"  maxlength="5" formControlName="zip_code">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Zip+4:</mat-label>
                    <input matInput type="text" maxlength="4" formControlName="zp4">
                </mat-form-field>
                

                <mat-form-field color="accent">
                    <mat-label>Payment Type:</mat-label>
                    <input matInput type="text"  formControlName="payment_type">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>Credit Card Number:</mat-label>
                    <input matInput type="text" maxlength="16" formControlName="cc_numbers">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>CC Exp:</mat-label>
                    <input matInput type="text" maxlength="4" formControlName="cc_exp">
                </mat-form-field>
                
                <mat-form-field color="accent">
                    <mat-label>CC CVC Code:</mat-label>
                    <input matInput type="text" maxlength="4" formControlName="cvc_code">
                </mat-form-field>
                
                <button mat-raised-button color="primary" [disabled]="!orders.customerFormBuilder.valid" (click)="saveCustomer()" >Add New Customer</button>
                <button mat-raised-button  (click)="goBackHome()" >Cancel</button>

               </div> 
        </div>   
    </form>

</div>

