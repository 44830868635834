
<h2 mat-dialog-title>Remove Customer: <br>
    {{this.orders.customerFormBuilder.get(['main_recipient']).value}} <br>
    {{this.orders.customerFormBuilder.get(['phone']).value}} <br>
    {{this.orders.customerFormBuilder.get(['address']).value}} <br>
    {{this.orders.customerFormBuilder.get(['city']).value}} {{this.orders.customerFormBuilder.get(['state']).value}} {{this.orders.customerFormBuilder.get(['zip_code']).value}}<br>
</h2>


    <div mat-dialog-content>
        <p style="color:red">WARNING!! <span style="color: white;"><br> Removing  is a NON-UNDO Command.<br>Are your sure?</span> <br>  
    </div>
    <div mat-dialog-actions>
    <button mat-stroked-button  mat-dialog-close>Go Back</button>
    <button mat-stroked-button  mat-dialog-close (click)="removeCustomer()" >YES</button>
    </div>      



