import { NavbarComponent } from './components/users/navbar/navbar.component';
import { OrdersService } from './services/orders.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SiginComponent } from './components/users/sigin/sigin.component';
import { HttpClientModule } from '@angular/common/http';

import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatBadgeModule} from '@angular/material/badge';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { MatTableExporterModule } from 'mat-table-exporter';

import { AddSearchDialogComponent } from './components/users/add-search-dialog/add-search-dialog.component';
import { AddNewCustomerComponent } from './components/users/add-new-customer/add-new-customer.component';
import { UpdateCustomerComponent } from './components/users/update-customer/update-customer.component';
import { AddNewOrderComponent } from './components/users/add-new-order/add-new-order.component';
import { ListCustomersComponent } from './components/users/list-customers/list-customers.component';
import { ListOrdersComponent } from './components/users/list-orders/list-orders.component';
import { EditordercustomerComponent } from './components/users/editordercustomer/editordercustomer.component';
import { DialogAskRemoveCustomerComponent } from './components/users/dialog-ask-remove-customer/dialog-ask-remove-customer.component';
import { DialogAskRemoveOrderComponent } from './components/users/dialog-ask-remove-order/dialog-ask-remove-order.component';
import { DialogSearchByAllComponent } from './components/users/dialog-search-by-all/dialog-search-by-all.component';
import { DialogAddNewOrderComponent } from './components/users/dialog-add-new-order/dialog-add-new-order.component';
import { FullcustomersearchComponent } from './components/users/fullcustomersearch/fullcustomersearch.component';
import { DialogShowOrderStatusComponent } from './components/users/dialog-show-order-status/dialog-show-order-status.component';
import { MatNativeDateModule } from '@angular/material/core';
import { SalesReportComponent } from './components/users/sales-report/sales-report.component';


@NgModule({
  declarations: [
    AppComponent,
    SiginComponent,
    NavbarComponent,
    AddSearchDialogComponent,
    AddNewCustomerComponent,
    UpdateCustomerComponent,
    AddNewOrderComponent,
    ListCustomersComponent,
    ListOrdersComponent,
    EditordercustomerComponent,
    DialogAskRemoveCustomerComponent,
    DialogAskRemoveOrderComponent,
    DialogSearchByAllComponent,
    DialogAddNewOrderComponent,
    FullcustomersearchComponent,
    DialogShowOrderStatusComponent,
    SalesReportComponent,
    
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatToolbarModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatDatepickerModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatTableExporterModule,
    MatSlideToggleModule
  ],
  providers: [OrdersService],
  bootstrap: [AppComponent]
})
export class AppModule { }
