import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dialog-show-order-status',
  templateUrl: './dialog-show-order-status.component.html',
  styleUrls: ['./dialog-show-order-status.component.css']
})
export class DialogShowOrderStatusComponent implements OnInit {
  dataSource:string[]=[];
  //trackingDetail = JSON.parse(this.orders.customerOrderForm.get(['track_summary_detail']).value);

  constructor(public orders:OrdersService) {}

  ngOnInit(): void {
    // if(this.orders.customerOrderForm.get(['delivery_type']).value == 'UPS'){
    //   this.trackingDetail = JSON.parse(this.orders.customerOrderForm.get(['track_summary_detail']).value);
    //   console.log(this.trackingDetail);
    //   let activityUpdates:number=this.trackingDetail.TrackResponse.Shipment[0].Package[0].Activity.length;
    //   let i:number=0;
    //   let actualActivity:string;
    //   console.log('Activity Updates: ' + activityUpdates);
    //   for(i; i < activityUpdates; i++ ){
    //     actualActivity = this.trackingDetail.TrackResponse.Shipment[0].Package[0].Activity[i].Status[0].StatusType[0].Description[0]
    //     if( i != (activityUpdates - 1)){
    //         actualActivity = actualActivity + ': ' +    
    //            this.trackingDetail.TrackResponse.Shipment[0].Package[0].Activity[i].ActivityLocation[0].Address[0].City[0] + ', ' +
    //            this.trackingDetail.TrackResponse.Shipment[0].Package[0].Activity[i].ActivityLocation[0].Address[0].StateProvinceCode[0] + ' ' +
    //            this.trackingDetail.TrackResponse.Shipment[0].Package[0].Activity[i].Date[0];        
    //     }
    //     console.log(i +')' +actualActivity);
    //     this.dataSource.push(actualActivity);
    //   }
    //   console.log(this.dataSource);
    //}
  }

}
