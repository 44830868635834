    <div mat-dialog-title>
        <h2>{{orders.customerOrderForm.get(['order_number']).value}}</h2>
        <h1>Status: {{orders.customerOrderForm.get(['delivery_status']).value}} </h1>
        <h2>{{orders.customerOrderForm.get(['status_detail']).value}}</h2>
    </div>

    <div mat-dialog-content>
        <h2 style="color: black;">
            {{this.orders.customerFormBuilder.get(['main_recipient']).value}} <br>
            {{this.orders.customerFormBuilder.get(['phone']).value}} <br>
            {{this.orders.customerFormBuilder.get(['address']).value}} <br>
            {{this.orders.customerFormBuilder.get(['city']).value}} <br>
            {{this.orders.customerFormBuilder.get(['state']).value}} {{this.orders.customerFormBuilder.get(['zip_code']).value}}<br>
        </h2>
        <!-- <div *ngFor="let activity of dataSource">
            <b style="color: grey;">{{activity}}</b>
        </div> -->


    </div>
    <div mat-dialog-actions>
    <button mat-stroked-button  mat-dialog-close>OK</button>
    </div>      



