import { UserRes } from './../../../services/dataType';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StickyDirection } from '@angular/cdk/table';

@Component({
  selector: 'app-sigin',
  templateUrl: './sigin.component.html',
  styleUrls: ['./sigin.component.css']
})
export class SiginComponent implements OnInit {
  hide = true;
  statusDate = new Date();

  constructor( public orders: OrdersService, private router:Router) { }

  ngOnInit(): void {

    if(JSON.parse(sessionStorage.getItem('user')) !== null){
      this.router.navigate(['api/authorized/listorders'])
    }else{
        // widow.reload() from .then 
    }

  }
    


  onSubmit(){
    // console.log(this.orders.acctuallUser.authorized)
    this.orders.authenticateUser().subscribe(res => {
      this.orders.acctuallUser.authorized = res.authorized
       if( this.orders.acctuallUser.authorized == "YES" ){
        // this.orders.acctuallUser.authorized = res.authorized
        // this.orders.acctuallUser.fullName = res.fullName
        // this.orders.acctuallUser.userType = res.userType
        this.orders.acctuallUser = res
        sessionStorage.setItem('user', JSON.stringify(this.orders.acctuallUser))
        this.router.navigate(['api/authorized/listorders'])
      }else{



      }

      })
    
  }

}
