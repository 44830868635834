import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-dialog-search-by-all',
  templateUrl: './dialog-search-by-all.component.html',
  styleUrls: ['./dialog-search-by-all.component.css']
})
export class DialogSearchByAllComponent implements OnInit {

  searchElement:String;

  constructor(private orders:OrdersService, private router:Router) { }

  fullSearchByElement(simpleForm){
    this.router.navigate(['api/authorized/listcustomers']);

    console.log('SEARCHING FOR:' + simpleForm.element + '.....');
    this.orders.fullCustomerSearch(simpleForm.element).subscribe(res=> {
      //console.log(res);
      if (res.length >=1){
        this.orders.customerFullSearchAnswer = res;
        this.router.navigate(['api/authorized/fullsearchcustomer']);
      }else{
        console.log('NULL SEARCH NO DATA FOUND');

      }
    });
  }


  ngOnInit(): void {
  }

}
