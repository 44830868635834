
<div class="mat-elevation-z8"  *ngIf="this.orders.acctuallUser.authorized == 'YES'  ">
    <mat-toolbar>
  
        <!-- Ordena Directo, Inc. -->
        <a mat-button matTooltip="All Orders" routerLink="/"><mat-icon>assignment</mat-icon></a> 
        <a mat-button matTooltip="All Contacts" routerLink="api/authorized/listcustomers"><mat-icon>contacts</mat-icon></a> 
        <!-- <button mat-button matTooltip="Search Customer" (click)="openDialogSearchByAll()" ><mat-icon>person_search</mat-icon></button> -->
        <button mat-button matTooltip="Add New Order" (click)="openDialogAddOrder()" ><mat-icon>person_add</mat-icon></button>
        <span class="nav-bar-spacer"></span>   
        <button *ngIf="this.orders.acctuallUser.userType == 'ADMIN'" mat-icon-button [matMenuTriggerFor]="menu" >
          <mat-icon >menu</mat-icon>
        </button>
          <mat-menu #menu="matMenu">
            <a mat-menu-item routerLink="api/authorized/neworders_report">
              <mat-icon>auto_graph</mat-icon>
              <span>New Orders Report</span>
            </a>

            <button mat-menu-item (click)="openDialogSearchByAll()">
              <mat-icon>person_search</mat-icon>
              <span>Search</span>
            </button>

            <button mat-menu-item (click)="openDialogAddOrder()">
              <mat-icon>add_circle</mat-icon>
              <span>Add Order</span>
            </button>

            <a mat-menu-item routerLink="api/authorized/listcustomers">
              <mat-icon>contacts</mat-icon>
              <span>List Customers</span>
            </a>
            <a mat-menu-item routerLink="api/authorized/listorders">
              <mat-icon>list</mat-icon>
              <span>List Orders</span>
            </a>

            <button mat-menu-item>
              <mat-icon>settings</mat-icon>
              <span>Settings</span>
            </button>
            <button mat-menu-item (click)="sigMeOut()">
              <mat-icon>logout</mat-icon>
              <span>Log Out</span>
            </button>
          </mat-menu>
        
        {{ this.orders.acctuallUser.fullName }}    
        <button type="button" mat-button matTooltip="Log Out" (click)="sigMeOut()"><mat-icon>logout</mat-icon></button>
    </mat-toolbar>
</div>


