import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-update-customer',
  templateUrl: './update-customer.component.html',
  styleUrls: ['./update-customer.component.css']
})
export class UpdateCustomerComponent implements OnInit {

  constructor(private router: Router, public orders: OrdersService) { }

  ngOnInit(): void {
  }

  addNewCustomer(){
    let loyalCustomer = true;
    this.orders.saveCustomer(loyalCustomer)
    .subscribe(res => {
      console.log(res);
    });
  }

  addNewOrder(){
    this.router.navigate(['api/authorized/addneworder']);
  }

  goBackHome(){
  }



}
