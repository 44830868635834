<div mat-dialog-title>
    <h1>Removing Order: </h1>
    <b>{{orders.customerOrderForm.get(['order_number']).value}}</b>
</div>
<div mat-dialog-content>
    {{this.orders.customerFormBuilder.get(['main_recipient']).value}} <br>
    {{this.orders.customerFormBuilder.get(['phone']).value}} <br>
    {{this.orders.customerFormBuilder.get(['address']).value}} <br>
    {{this.orders.customerFormBuilder.get(['city']).value}} {{this.orders.customerFormBuilder.get(['state']).value}} {{this.orders.customerFormBuilder.get(['zip_code']).value}}<br>
    <p style="color:red">WARNING!1</p> 
     Removing  is a NON-UNDO Command. <br> Are your sure? <br>
     Index: {{data.index}}
</div>
<div mat-dialog-actions>
    <button mat-stroked-button  mat-dialog-close mat-dialog-close="true">YES</button>
    <!-- <button mat-stroked-button  mat-dialog-close mat-dialog-close="true" (click)="removeOrder()">YES</button> -->
    <button mat-stroked-button  mat-dialog-close mat-dialog-close="false">Go Back</button>
</div>




