import { DialogSearchByAllComponent } from './../dialog-search-by-all/dialog-search-by-all.component';
import { AddSearchDialogComponent } from './../add-search-dialog/add-search-dialog.component';
import { UserRes } from './../../../services/dataType';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  constructor(public orders:OrdersService, private router:Router, public addDialog: MatDialog) { }

  ngOnInit(): void {
    if(JSON.parse(sessionStorage.getItem('user')) !== null){
      console.log(this.orders.acctuallUser);
      this.orders.acctuallUser = JSON.parse(sessionStorage.getItem('user'));
      console.log(this.orders.acctuallUser);
    }else
    this.router.navigate(['/'])
  }

  openDialogSearchByAll(){
    this.addDialog.open(DialogSearchByAllComponent,{width: '350px'});
 } 

 sigMeOut(){
   sessionStorage.removeItem('user');
   console.log('CLOSING SESSION....')
   this.orders.sessionSigOut().subscribe( res => console.log(res));
   this.router.navigate(['/']).then( () => {window.location.reload()} );
 }

openDialogAddOrder(){

  console.log(this.orders.customerFormBuilder.get(['customer_id']).value);
  this.addDialog.open(AddSearchDialogComponent, { width: '350px' });
}

}
