import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'

@Component({
  selector: 'app-add-search-dialog',
  templateUrl: './add-search-dialog.component.html',
  styleUrls: ['./add-search-dialog.component.css']
})
export class AddSearchDialogComponent implements OnInit {
localCustomerID = '';
customerID:string;
customerArrayID:string[];
newOrderNumber:string;
newPhoneNumber:string;

  constructor(public orders:OrdersService, private router: Router) { }

  ngOnInit(): void {
    // this.orders.customerFormBuilder.patchValue({customer_id:''})
  }

  onSubmit(){
    this.customerID = this.orders.customerFormBuilder.get(['customer_id']).value;
    this.customerArrayID = this.customerID.split(''); 
    this.newPhoneNumber = '('+this.customerArrayID[0]+this.customerArrayID[1]+this.customerArrayID[2]+')'+' '+this.customerArrayID[3]+this.customerArrayID[4]+this.customerArrayID[5]+'-'+this.customerArrayID[6]+this.customerArrayID[7]+this.customerArrayID[8]+this.customerArrayID[9];
    

    // console.log(this.orders.customerOrderForm.value);
    // console.log(this.orders.customerFormBuilder.get(['customer_id']).valid);
    // console.log(this.orders.customerFormBuilder.get(['customer_id']).value);
    // console.log(this.orders.acctuallUser.authorized);

    this.generateNewOrderNumber();
    this.orders.getCustomerByID(this.customerID).subscribe(res => {
      if(res.length >= 1 ){
        // console.log(res);
        this.orders.customerFormBuilder.patchValue(res[0]);
        // console.log(this.orders.customerFormBuilder.value);
        this.router.navigate(['api/authorized/addneworder']);

      }else if( res.length == 0){

        let newCustomer = true;
        this.cleanCustomerForm();
        this.orders.customerFormBuilder.patchValue({customer_id: this.customerID, phone:this.newPhoneNumber});
        // console.log(this.orders.customerFormBuilder.value);
        this.orders.saveCustomer(!newCustomer).subscribe(res => {
  
          // console.log(res);
          // console.log(res.insertId);
          this.orders.customerFormBuilder.patchValue({id : res.insertId});
          this.router.navigate(['api/authorized/addneworder']);
   
        });


      }
      });
    
  }

  generateNewOrderNumber(){
    let today = new Date();
    let shippingDate = new Date();
    let deliveryDate = new Date();

    let todayYear = today.getFullYear().toString().split('');
    let towDYear = todayYear[2] + todayYear[3];
    let agentID = this.orders.getAgentID() + 77;  
    // let customerArrayID :string[] = this.orders.customerFormBuilder.get(['customer_id']).value.split(''); 
    let phoneLine: string = this.customerArrayID[6] + this.customerArrayID[7] + this.customerArrayID[8] + this.customerArrayID[9] ;
    // let newOrderNumber:string;

    shippingDate.setDate(today.getDate() + 3);
    deliveryDate.setDate(today.getDate() + 7);


    this.orders.customerOrderForm.reset();
    this.orders.customerOrderForm.patchValue({
      id : '',
      order_number : '',
      customer_id : '',
      order_date : today,
      shipping_date : shippingDate,
      delivery_date : deliveryDate,
      delivery_type : 'UPS',
      delivery_status: 'Pending...',
      status_detail: 'Waiting for Tracking Number.',
      status_note : '',
      tracking_number : '',
      payment_status : 'PAID',
      msrp_price : '259',
      total_discount : '100',
      total_shipping : '14.99',
      total : '159',
      product01_id: 'LECHE DE ALPISTE',
      quantity_01 : '4',
      product02_id: 'INSTAMIXER',
      quantity_02 : '1',
      product03_id : '',
      quantity_03 : '',
      cod_tracking : '',
      track_summary : '',
      track_summary_detail : '',
      auth_code : '',
    });
    this.orders.customerOrderForm.patchValue({customer_id: this.customerID});

    this.orders.dataBaseInfo().subscribe(res => {
      console.log(res);
       agentID = agentID + res.week + 1;
       this.newOrderNumber = towDYear + 'W' + this.toTwoDgts(res.week) + this.toTwoDgts(today.getMonth()) + this.toTwoDgts(today.getDate()-1) + phoneLine + 'A' + agentID + res.dataCount;
       this.orders.customerOrderForm.patchValue({order_number: this.newOrderNumber});
       console.log(this.newOrderNumber);
      });


  }

  toTwoDgts(number:number):string{
    let res:string;
    let numTemp:number = number +1;

    if(numTemp <= 9 ){
      res = '0' + numTemp.toString();
    }else{
      res = numTemp.toString();
    }
    return res;
  }

  cleanCustomerForm(){
    this.orders.customerFormBuilder.reset();
    this.orders.customerFormBuilder.patchValue({
      id : '',
      customer_id: '',
      phone: '',
      biz_name: '',
      main_recipient: '',
      secn_recipient: '',
      phone2: '',
      address_type: 'HOME/APT/SPC/STE',
      address: '',
      address_detail: '',
      city: '',
      state: '',
      zip_code: '',
      zp4: '',
      payment_type: 'CARD',
      cc_numbers: '',
      cc_exp: '',
      cvc_code: '',
      email: '',
      since: '',
      cards:'[]'
    });
  }
}
