<div class="welcome">
    <div class="signin-window">
        <h1>Welcome to Ordena Directo!</h1>
        <form [formGroup]="orders.formUser" (ngSubmit)="onSubmit()">
            <mat-form-field class="form-field-full"  appearance="fill">
                <mat-label>Username:</mat-label>
                <input matInput type="text" formControlName="userName" autofocus placeholder="Enter your Username here.">
            </mat-form-field>
            <br>
            <mat-form-field class="form-field-full" appearance="fill">
                <mat-label>Enter your password</mat-label>
                <input matInput [type]="hide ? 'password' : 'text'" formControlName="userPass">
                <button type="button" mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
            </mat-form-field>
            <button class="form-field-full" mat-raised-button color="primary" type="submit" [disabled]="!orders.formUser.valid">Sign In</button>
        </form>
    </div>
</div>

