
  <main>
    <mat-form-field>
      <mat-label>Quick Search</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Quick Search" #input>
    </mat-form-field>     
     <div class="mat-elevation-z8 table-container" >
      <table mat-table [dataSource]="dataSource" matTableExporter #exporter="matTableExporter">
        <ng-container matColumnDef="edit">
            <th mat-header-cell *matHeaderCellDef>Add New Order</th>
            <td mat-cell *matCellDef="let element"><button [disabled]="this.orders.acctuallUser.userType != 'ADMIN'" mat-button (click)="addNewOrder(element.customer_id)"><mat-icon>add</mat-icon></button></td>
        </ng-container>
        <ng-container matColumnDef="remove">
            <th mat-header-cell *matHeaderCellDef>Remove</th>
            <td mat-cell *matCellDef="let element"><button [disabled]="this.orders.acctuallUser.userType != 'ADMIN'" mat-button (click)="removeCustomer(element.customer_id)"><mat-icon>delete</mat-icon></button></td>
        </ng-container>

        <ng-container matColumnDef="main_recipient">
          <th mat-header-cell *matHeaderCellDef> Customer Name </th>
          <td mat-cell *matCellDef="let element"> 
            <b>{{element.main_recipient}}</b>     
          </td>
        </ng-container>
  
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef> Address</th>
          <td mat-cell *matCellDef="let element"> {{element.address}} {{element.address_detail}}</td>
        </ng-container>
  
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> City </th>
          <td mat-cell *matCellDef="let element"> {{element.city}} </td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef> State </th>
          <td mat-cell *matCellDef="let element"> {{element.state}} </td>
        </ng-container>

        <ng-container matColumnDef="zip_code">
          <th mat-header-cell *matHeaderCellDef> ZipCode </th>
          <td mat-cell *matCellDef="let element"> {{element.zip_code}} - {{element.zp4}}</td>
        </ng-container>
        <ng-container matColumnDef="cc_numbers">
          <th mat-header-cell *matHeaderCellDef> Payment </th>
          <td mat-cell *matCellDef="let element"> {{element.cc_numbers }}EXP:{{element.cc_exp}}CVC:{{element.cvc_code}}</td>
        </ng-container>
      


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
        
    </table>
    <mat-paginator class="table-paginator"
    [length]="1000"
    [pageSize]="15"
    [pageSizeOptions]="[25, 50, 100]"
    showFirstLastButtons>
    </mat-paginator>
  
    </div>
    <div class="table-container">
      <button mat-raised-button (click)="exporter.exportTable('xlsx',{fileName:'Customers_'})">Excel</button>
      <button mat-raised-button (click)="exporter.exportTable('csv',{fileName:'Customers_'})">CSV</button>
      <button mat-raised-button (click)="exporter.exportTable('json',{fileName:'Customers_'})">JSON</button>
      <button mat-raised-button (click)="exporter.exportTable('txt',{fileName:'Customers_'})">Text</button>
    </div>
  </main>