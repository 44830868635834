import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-new-customer',
  templateUrl: './add-new-customer.component.html',
  styleUrls: ['./add-new-customer.component.css']
})
export class AddNewCustomerComponent implements OnInit {

  constructor(public orders: OrdersService) { }

  ngOnInit(): void {
  }


  saveCustomer(){
    this.orders.saveCustomer(false)
    .subscribe(res => {
      console.log(res);
    });
  }

  goBackHome(){
  }






}
