import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { CCard } from 'src/app/services/dataType';

@Component({
  selector: 'app-editordercustomer',
  templateUrl: './editordercustomer.component.html',
  styleUrls: ['./editordercustomer.component.css']
})
export class EditordercustomerComponent implements OnInit {
  tempCardNumber:string='Add New Card Number';
  tempCardExp:string='Exp';
  tempCardCvc:string='CVC';
  cards: CCard[] = [];


  constructor(public orders: OrdersService, private router: Router) { }

  ngOnInit(): void {
    this.orders.getCustomerByID(this.orders.customerFormBuilder.get(['customer_id']).value).subscribe(res => {
      this.orders.customerFormBuilder.patchValue(res[0]);
      this.cards = JSON.parse(this.orders.customerFormBuilder.get(['cards']).value);
    } );
    // this.orders.getOrderByNumber(this.orders.customerFormBuilder.get(['order_number']).value).subscribe(res => {this.orders.customerOrderForm.patchValue(res[0])} );
    console.log(this.orders.customerFormBuilder.get(['cards']).value);
    
  }
  addNewCard(newNumber,newExp,newCVC){
    let newCard={
      cardNumber : newNumber.value,
      exp: newExp.value,
      cvc: newCVC.value
    };
    console.log(newCard);
    this.cards.push(newCard);
    newNumber.value='';
    newExp.value='';
    newCVC.value='';
    console.log(this.cards);
    console.log(JSON.stringify(this.cards));
    this.orders.customerFormBuilder.patchValue({cards:JSON.stringify(this.cards)});
  }

  removeCard(index:number){
    this.cards.splice(index,1);
    this.orders.customerFormBuilder.patchValue({cards:JSON.stringify(this.cards)});

  }

  updateCustomer(){
    let loyalCustomer = true;
    this.orders.saveCustomer(loyalCustomer)
    .subscribe(res => {
      console.log(res);
    });

  }

  goBackHome(){
    this.router.navigate(['/']).then(()=>window.location.reload());
  }

  updateOrder(){

    this.orders.updateOrder().subscribe(res => console.log(res));
    
  }
}
