import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { SalesReport } from './../../../services/dataType';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.css']
})
export class SalesReportComponent implements OnInit {

  displayedColumns: string[] = [
    // 'id',
    // 'delivery_status',
    'index',
    'biz_name',
    'main_recipient',
    'address',
    'city',
    'state',
    'zip_code',
    'country',
    'phone',
    'email',
    'order_number',
];  

dataSource : SalesReport[] = [];


  constructor(public orders:OrdersService, private router:Router) { 
    this.orders.salesReport().subscribe(res => {
      console.log(res);
      this.dataSource = res;
  
    });

  }

  ngOnInit(): void {
  }

}
