import { AddSearchDialogComponent } from './../add-search-dialog/add-search-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Customer } from './../../../services/dataType';
import { DialogAskRemoveCustomerComponent } from './../dialog-ask-remove-customer/dialog-ask-remove-customer.component';



@Component({
  selector: 'app-fullcustomersearch',
  templateUrl: './fullcustomersearch.component.html',
  styleUrls: ['./fullcustomersearch.component.css']
})
export class FullcustomersearchComponent {
  displayedColumns: string[] = [
    'edit',
    'phone',
    'main_recipient',
    'address',
    'city',
    'state',
    'zip_code',
    'cc_numbers',
    'remove'
  ];  



  customersDB: Customer[] = [];
  dataSource = new MatTableDataSource(this.customersDB);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public orders:OrdersService,private router:Router, private askDialog:MatDialog) {
    console.log('CUSTOMER LIST DATA OK');
    this.customersDB = this.orders.customerFullSearchAnswer;
    this.dataSource = new MatTableDataSource(this.orders.customerFullSearchAnswer);
    this.dataSource.paginator = this.paginator;
    console.log('CUSTOMER LIST DATA OK acccc FULL SEARCH....');
   }
   addNewOrder(customerID:string){
    this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));
    this.askDialog.open(AddSearchDialogComponent,{width:'350px'});

  }


   removeCustomer(customerID:string){
    console.log( 'deleting: ' + customerID );
    this.orders.customerFormBuilder.patchValue({customer_id: customerID});
    console.log(this.orders.customerFormBuilder.get(['customer_id']).value);
    this.askDialog.open(DialogAskRemoveCustomerComponent, { width: '350px' });

   //  this.orders.removeCustomer(customerID).subscribe((res)=>{console.log(res)});
   //  this.router.navigate(['api/authorized/listcustomers']).then( () => window.location.reload() );

  }
 
 applySearch(seachValue:string){
   this.dataSource.filter = seachValue.trim().toLowerCase();
 }

  ngOnInit(): void {}


}
