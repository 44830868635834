
<h1 mat-dialog-title>Add New Order</h1>

    <form [formGroup]="orders.customerFormBuilder">
        <div mat-dialog-content>
            <p>Enter 10 Digits Phone Number</p>
        <mat-form-field>
            <mat-label>Only Numbers Allowed</mat-label>
            <input matInput #phonedigitcouter formControlName="customer_id" maxlength="10" autofocus>
            <mat-hint  >{{phonedigitcouter.value?.length || 0}}/10</mat-hint>
        </mat-form-field>
        </div>
        <div mat-dialog-actions>
        <button mat-stroked-button type="button" mat-dialog-close>Go Back</button>
        <button mat-stroked-button type="button" (click)="cleanCustomerForm()">Clear</button>
        <button mat-stroked-button type="submit" (click)="onSubmit()" mat-dialog-close [disabled]="!orders.customerFormBuilder.get(['customer_id']).valid ">Ok</button>
        </div>      
    </form>
  

