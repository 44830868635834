import { AddSearchDialogComponent } from './../add-search-dialog/add-search-dialog.component';
import { DialogAskRemoveCustomerComponent } from './../dialog-ask-remove-customer/dialog-ask-remove-customer.component';
import { Router } from '@angular/router';
import { Customer } from './../../../services/dataType';
import { OrdersService } from './../../../services/orders.service';
import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-list-customers',
  templateUrl: './list-customers.component.html',
  styleUrls: ['./list-customers.component.css']
})
export class ListCustomersComponent{

  displayedColumns: string[] = [
    'edit',
    'phone',
    'main_recipient',
    'address',
    'city',
    'state',
    'zip_code',
    'cc_numbers',
    'remove'
  ];  



  customersDB: Customer[] = [];
  dataSource = new MatTableDataSource(this.customersDB);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public orders: OrdersService, private router:Router, private askDialog:MatDialog) {
    this.orders.getNewCustomers().subscribe(customers =>{
      console.log('CUSTOMER LIST DATA OK');
      this.customersDB = customers;
      this.dataSource = new MatTableDataSource(customers);
      this.dataSource.paginator = this.paginator;
    });
   }
   addNewOrder(customerID:string){
    this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));
    this.askDialog.open(AddSearchDialogComponent,{width:'350px'});

  }

   removeCustomer(customerID:string){
     console.log( 'deleting: ' + customerID );
     this.orders.customerFormBuilder.patchValue({customer_id: customerID});
     console.log(this.orders.customerFormBuilder.get(['customer_id']).value);
     this.askDialog.open(DialogAskRemoveCustomerComponent, { width: '350px' });

    //  this.orders.removeCustomer(customerID).subscribe((res)=>{console.log(res)});
    //  this.router.navigate(['api/authorized/listcustomers']).then( () => window.location.reload() );

   }
  
   applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  
  applySearch(seachValue:string){
    this.dataSource.filter = seachValue.trim().toLowerCase();
  }
 
  ngOnInit(): void {
  }

}
