
import { UserRes, Customer, InsertNew, Order, CustomerOrder, SalesReport } from './dataType';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormControl, FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})


export class OrdersService {
  today = new Date();



  customerFullSearchAnswer:Customer[]=[];
  
  acctuallUser: UserRes = {
    fullName: "",
    userType: "",
    authorized: ""
  }

  userToken: string = "di";
  passToken: string = "Direct02022$";
  // server_URL: string ="http://localhost:5500";
  server_URL: string ="https://www.databasecentral.space";
  searchElement: string ="known";

  formUser = this.fb.group({
    userName: ['', Validators.required],
    userPass: ['', Validators.required]
  })

  customerOrderForm = this.fb.group({
    id: new FormControl(''),
    order_number: new FormControl(''),
    customer_id: new FormControl(''),
    order_date: new FormControl(''),
    shipping_date: new FormControl(''),
    delivery_date: new FormControl(''),
    delivery_type: new FormControl(''),
    delivery_status: new FormControl('Pending...'),
    status_detail: new FormControl(''),
    status_note: new FormControl(''),
    tracking_number: new FormControl(''),
    payment_status: new FormControl('PAID'),
    msrp_price: new FormControl(''),
    total_discount: new FormControl(''),
    total_shipping: new FormControl(''),
    total: new FormControl(''),
    product01_id: new FormControl('4411'),
    quantity_01: new FormControl(''),
    product02_id: new FormControl('4417'),
    quantity_02: new FormControl(''),
    product03_id: new FormControl(''),
    quantity_03: new FormControl(''),
    cod_tracking: new FormControl(''),
    track_summary: new FormControl(''),
    track_summary_detail: new FormControl(''),
    auth_code: new FormControl('')
  });

  customerFormBuilder  = this.fb.group({
    id: new FormControl(''), 
    customer_id: ['', [Validators.required, Validators.pattern("[0-9]{10}")] ],
    biz_name: new FormControl(''),
    main_recipient: ['',[Validators.required]],
    phone: new FormControl('',[Validators.required]),
    secn_recipient: new FormControl(''),
    phone2: new FormControl(''),
    email: new FormControl('',[Validators.email]),
    address_type: new FormControl(''),
    address: new FormControl('',[Validators.required]),
    address_detail: new FormControl(''),
    city: new FormControl('',[Validators.required]),
    state: new FormControl('',[Validators.required, Validators.minLength(2), Validators.pattern("[A-Z]{2}")]),
    zip_code: new FormControl('',[Validators.required, Validators.pattern("[0-9]{5}-?([0-9]{4})?")]),
    zp4: new FormControl('',[Validators.minLength(4), Validators.pattern("[0-9]{4}")]),
    payment_type: new FormControl(''),
    cc_numbers: new FormControl('',[Validators.pattern("[0-9]{15,16}")]),
    cc_exp: new FormControl('',[Validators.pattern("[0-9]{4}")]),
    cvc_code: new FormControl('',[Validators.pattern("[0-9]{3,4}")]),
    cards: new FormControl('')
  });
  
  constructor(private fb: FormBuilder, private http: HttpClient) { }

  fullCustomerSearch(element:string):Observable<Customer[]>{
    if(this.acctuallUser.authorized == 'YES'){
      
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
        element: element
      };

      return this.http.post<Customer[]>(this.server_URL + '/fullcustomersearchbyelement', postPack);
    }else{
      return null;
    }


  }

  authenticateUser(){
    const postPack =  {
      "userID" : this.formUser.controls['userName'].value,
      "passcode" : this.formUser.controls['userPass'].value,
      "addressType": this.userToken,
      "addressSource": this.passToken
    }
    return this.http.post<UserRes>(this.server_URL +  '/authenticate', postPack)
  };

  salesReport():Observable<SalesReport[]>{
    const postPack =  {
      addressType: "di",
      addressSource: "Direct0845@",
      isAuthorized : true
    };
    console.log('TODAY SALES REPORT');
    return this.http.post<SalesReport[]>(this.server_URL + '/sales_report', postPack);
  }
  
  addNewOrder():Observable<InsertNew>{
    if(this.acctuallUser.authorized == 'YES'){
      let tempOrderNumber = this.customerOrderForm.get(['order_number']).value;
      let finalOrderNumber = this.customerOrderForm.get(['quantity_01']).value + 'PK' + tempOrderNumber; 
      this.customerOrderForm.patchValue({
        order_number: finalOrderNumber,
        msrp_price: this.customerOrderForm.get(['total']).value * 2,
        total_discount: this.customerOrderForm.get(['total']).value
      });
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
        jsonDataCustomer: this.customerOrderForm.value
      };
      return this.http.post<InsertNew>(this.server_URL + '/addneworder', postPack);
    }else{
      return null;
    }
  };

  updateOrder():Observable<InsertNew>{
    if(this.acctuallUser.authorized == 'YES'){
      
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
          id: this.customerOrderForm.get('id').value,
          jsonOrderData: this.customerOrderForm.value
        };

        return this.http.post<InsertNew>(this.server_URL + '/updateorder', postPack);
      }else{
        return null;
      }

  }

  saveCustomer( loyalCustomer:boolean ):Observable<InsertNew>{
      if(this.acctuallUser.authorized == 'YES'){
        if( loyalCustomer ){
          const postPack= {
            addressType: "di",
            addressSource: "Direct0845@",
            isAuthorized : true,
            id: this.customerFormBuilder.get('id').value,
            jsonDataCustomer: this.customerFormBuilder.value
          };
          return this.http.post<InsertNew>(this.server_URL + '/updatecustomer', postPack);
        }else{
          const postPack= {
            addressType: "di",
            addressSource: "Direct0845@",
            isAuthorized : true,
            jsonDataCustomer: this.customerFormBuilder.value
          };
          // console.log(this.customerFormBuilder.value);
          return this.http.post<InsertNew>(this.server_URL + '/addnewcustomer', postPack);
        }
      }else{  
        return null;
      }
  };


    getCustomerByID(customerID:string):Observable<Customer[]>{
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
        customerID: customerID
      };
      return this.http.post<Customer[]>(this.server_URL + '/getcustomerbyid', postPack );

    }
    getOrderByNumber(orderNumber:string):Observable<Order[]>{
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
        //orderNumber: this.customerOrderForm.get(['order_number']).value
        orderNumber: orderNumber
      };
      return this.http.post<Order[]>(this.server_URL + '/getorderbynumber', postPack );

    }
    getOrdersByCustomerID(customerID:string):Observable<Order[]>{
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
        customerID: customerID
      };
      return this.http.post<Order[]>(this.server_URL + '/customer_orders', postPack );

    }


    dataBaseInfo():Observable<any>{
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
      };
      return this.http.post(this.server_URL + '/getdatabaseinfo', postPack);
    }

    getNewCustomers():Observable<Customer[]>{
      if(this.acctuallUser.authorized == 'YES'){
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
        };
        return this.http.post<Customer[]>(this.server_URL + '/newcustomers', postPack);
      }else{
        return null;
      }     
    };

    getNewOrders():Observable<Order[]>{
      if(this.acctuallUser.authorized == 'YES'){
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
        };
        return this.http.post<Order[]>(this.server_URL + '/neworders', postPack);
      }else{
        return null;
      }     
    };

    getCustomerOrders():Observable<CustomerOrder[]>{
      if(this.acctuallUser.authorized == 'YES'){
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
        };
        return this.http.post<CustomerOrder[]>(this.server_URL + '/unioncustomerorderlist', postPack);
      }else{
        return null;
      }     
    };


    getAgentID(){
      return 3304;
    }

    removeOrder(orderNumber:string){
      if(this.acctuallUser.authorized == 'YES'){
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
          order_number: orderNumber
        };
        return this.http.post(this.server_URL + '/removeorder', postPack);
      }else{
        return null;
      }     
    }
    removeCustomer(customerID:string){
      if(this.acctuallUser.authorized == 'YES'){
        const postPack= {
          addressType: "di",
          addressSource: "Direct0845@",
          isAuthorized : true,
          customer_id: customerID
        };
        return this.http.post(this.server_URL + '/removecustomer', postPack);
      }else{
        return null;
      }     
    }

    sessionSigOut():Observable<any>{
      const postPack= {
        addressType: "di",
        addressSource: "Direct0845@",
        isAuthorized : true,
      };
      console.log('.....');
      return this.http.post(this.server_URL + '/sessionsigOut', postPack);

    }

    trackPackage(trackingNumber:string,carrier:string):Observable<any>{
      const serverPack={
        trackingNumber:trackingNumber,
        carrier: carrier
      };

      return this.http.post(this.server_URL + '/api/trackpackage', serverPack);
    }


}