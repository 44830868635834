
<h1 mat-dialog-title>Full Customer Search</h1>

<form #searchForm="ngForm" (ngSubmit)="fullSearchByElement(searchForm.value)">
    <div mat-dialog-content>
        <p>Enter any data: </p>
    <mat-form-field>
        <mat-label>Full Search</mat-label>
        <input matInput ngModel #element name="element" id="element" maxlength="20" autofocus>
        <mat-hint  >{{element.value?.length || 0}}/20</mat-hint>
    </mat-form-field>
    </div>
    <div mat-dialog-actions>
    <button type="button" mat-stroked-button mat-dialog-close>Go Back</button>
    <button type="submit" [disabled]="!(element.value?.length >= 1)"  mat-stroked-button mat-dialog-close (click)="fullSearchByElement(searchForm.value)">Search</button>
    </div>      
</form>


