import { AddSearchDialogComponent } from './../add-search-dialog/add-search-dialog.component';
import { DialogShowOrderStatusComponent } from './../dialog-show-order-status/dialog-show-order-status.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { CustomerOrder } from './../../../services/dataType';
import { Component, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DialogAskRemoveOrderComponent } from '../dialog-ask-remove-order/dialog-ask-remove-order.component';

@Component({
  selector: 'app-list-orders',
  templateUrl: './list-orders.component.html',
  styleUrls: ['./list-orders.component.css']
})
export class ListOrdersComponent {

  displayedColumns: string[] = [
    'delivery_status', 
    'main_recipient',
    'add',
    'total',
    'phone',
    //'city',
    //'state',
    // 'zip_code',
    'order_number',
    'tracking_number',
    //'status_detail',
    'address',
    'delete'
  ];  
  ordersDB:CustomerOrder[] = [];
  dataSource = new MatTableDataSource(this.ordersDB);
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(public orders: OrdersService, private router:Router,private askDialog:MatDialog) {
    this.orders.getCustomerOrders().subscribe(data =>{
        console.log('ORDER&CUSTOMER DATA OK');
        this.ordersDB = data;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
    });
   }

  addNewOrder(customerID:string){
    this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));
    this.askDialog.open(AddSearchDialogComponent,{width:'350px'});
  }
  

  viewZoomInPackage(orderNumber:string, customerID:string){
    this.orders.getOrderByNumber(orderNumber).subscribe(res => this.orders.customerOrderForm.patchValue(res[0]));
    this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));
    this.askDialog.open(DialogShowOrderStatusComponent, { width: '450px' });
  } 
  
  trackPackage(trackingNumber:string,carrier:string, orderNumber:string, customerID:string, index:number){    
    this.orders.getOrderByNumber(orderNumber).subscribe(res => {this.orders.customerOrderForm.patchValue(res[0])} );
    if(trackingNumber != ''){
      this.orders.trackPackage(trackingNumber,carrier).subscribe(res=>{
        console.log(res);
        let trackSummary:string='';
        let trackDetail:string='';
        let statusTime: string;
        let deliveryStatus:string='Pending...';
        trackDetail = JSON.stringify(res);
  
        if(carrier == 'UPS'){
          console.log('Code: ' + res.TrackResponse.Shipment[0].Package[0].Activity[0].Status[0].StatusType[0].Code[0]);
          if(res.TrackResponse.Shipment[0].Package[0].Activity[0].Status[0].StatusType[0].Code[0] == 'M'){
            deliveryStatus = 'Label On System';
            trackSummary = res.TrackResponse.Shipment[0].Package[0].Activity[0].Status[0].StatusType[0].Description[0];
          }else{
            let activityUpdates:number=res.TrackResponse.Shipment[0].Package[0].Activity.length;
            console.log('Activity Updates: ' + activityUpdates);
            statusTime = res.TrackResponse.Shipment[0].Package[0].Activity[0].Time[0];
            let statusTimeArray = statusTime.split('');
            trackSummary =
            res.TrackResponse.Shipment[0].Package[0].Activity[0].Status[0].StatusType[0].Description[0] + ': ' +
            res.TrackResponse.Shipment[0].Package[0].Activity[0].ActivityLocation[0].Address[0].City[0] + ', ' +
            res.TrackResponse.Shipment[0].Package[0].Activity[0].ActivityLocation[0].Address[0].StateProvinceCode[0] + ' ' +
            res.TrackResponse.Shipment[0].Package[0].Activity[0].Date[0] + '@' +
            statusTimeArray[0]+statusTimeArray[1]+':'+statusTimeArray[2]+statusTimeArray[3]+'Hrs. ';

            if(res.TrackResponse.Shipment[0].Package[0].Activity[0].ActivityLocation[0].Description !== undefined){
              trackSummary = trackSummary + res.TrackResponse.Shipment[0].Package[0].Activity[0].ActivityLocation[0].Description;
            }
            if(res.TrackResponse.Shipment[0].Package[0].Activity[0].Status[0].StatusType[0].Code[0] == 'D'){
              deliveryStatus = 'DELIVERED';
            }else{
              if(trackSummary.includes('Out For Delivery'))
                deliveryStatus = 'Out For Delivery';
              else
                if(trackSummary.includes('Drop-Off'))
                  deliveryStatus = 'Drop-Off';
                else{
                  if(res.TrackResponse.Shipment[0].ScheduledDeliveryDate !== undefined)
                    trackSummary = trackSummary + 'Scheduled: '+ res.TrackResponse.Shipment[0].ScheduledDeliveryDate[0];
                  else
                    trackSummary = trackSummary + 'ReScheduled: '+ res.TrackResponse.Shipment[0].Package[0].RescheduledDeliveryDate;
                  deliveryStatus = 'In Transit';
                }
            }
          }
        }else{
          if(carrier == 'USPS'){
            if(trackDetail.includes('Error')){ 
              trackSummary = res.TrackResponse.TrackInfo[0].TrackSummary[0];
              deliveryStatus = "Label On System";
            }else{
              if(res.TrackResponse.TrackInfo[0].TrackSummary[0].includes('was delivered') ||
                 res.TrackResponse.TrackInfo[0].TrackSummary[0].includes('been delivered') ||
                 res.TrackResponse.TrackInfo[0].TrackSummary[0].includes('was picked') ){
                console.log('was delivered or was picked');
                trackSummary = res.TrackResponse.TrackInfo[0].TrackSummary[0];
                deliveryStatus = "DELIVERED";
              }else{
                if(res.TrackResponse.TrackInfo[0].TrackSummary[0].includes('label has been prepared'))
                  deliveryStatus = "Label On System";
                else
                  deliveryStatus = "In Transit";
                trackSummary = res.TrackResponse.TrackInfo[0].TrackSummary[0];
              }  
            }
          }
        }  
        
        console.log(deliveryStatus);
        console.log(trackSummary);
        console.log(index);
        console.log(this.dataSource.data[index].delivery_status);
        this.dataSource.data[index].delivery_status = deliveryStatus;
  
        this.orders.customerOrderForm.patchValue({
          track_summary_detail:trackDetail, 
          track_summary:trackSummary.toUpperCase(),
          status_detail:trackSummary,
          delivery_status:deliveryStatus});
        this.orders.updateOrder().subscribe(res => console.log(res));
  
        this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]) );
  
        this.askDialog.open(DialogShowOrderStatusComponent, { width: '370px' });
        //this.router.navigate(['/']);
        //this.router.navigate(['api/authorized/listorders']).then(()=>window.location.reload());

      });
    }

    

  }


  editOrder(orderNumber:string, customerID:string){
    this.orders.customerFormBuilder.patchValue({customer_id:customerID});
    // this.orders.customerOrderForm.patchValue({order_number:orderNumber});
    
    console.log(orderNumber,customerID);
    this.orders.getCustomerByID(customerID).subscribe(res => {this.orders.customerFormBuilder.patchValue(res[0])} );
    this.orders.getOrderByNumber(orderNumber).subscribe(res => {this.orders.customerOrderForm.patchValue(res[0])} );

    console.log(this.orders.customerOrderForm.value);
    console.log(this.orders.customerFormBuilder.value);

    this.router.navigate(['api/authorized/editordercustomer']);   

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  removeOrder(orderNumber:string, customerID:string, index:number){
    console.log('Order Re-Moved OK ' + orderNumber);
    console.log(index);
    this.orders.getOrderByNumber(orderNumber).subscribe(res => this.orders.customerOrderForm.patchValue(res[0]));
    this.orders.getCustomerByID(customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));


    // let dialogRef = this.askDialog.open(DialogAskRemoveOrderComponent,{width:'500px', data:{index:index}});
    let dialogRef = this.askDialog.open(DialogAskRemoveOrderComponent,{data:{index:index}});
    dialogRef.afterClosed().subscribe(res => {
      console.log(res);
      if(res=='true'){
        
        console.log(this.dataSource.data.splice(index,1));
        console.log(`Deleting Index: ${index}`);
        this.orders.removeOrder(orderNumber).subscribe(res => console.log('Removing Order: ' + orderNumber));
        this.router.navigate(['/']);
        
      }
      
    });

    // this.orders.removeOrder(orderNumber).subscribe( res => {console.log(res)});
    // this.router.navigate(['api/authorized/listorders']).then( () => window.location.reload() );
  }

  ngOnInit(): void {
  }

}
