
  <main>
        <div class="table-container">
        <div>
            <button mat-raised-button (click)="exporter.exportTable('xlsx',{fileName:'Orders_2021_'})">Excel</button>
            <button mat-raised-button (click)="exporter.exportTable('json',{fileName:'Orders_2021_'})">JSON</button>
            <button mat-raised-button (click)="exporter.exportTable('txt',{fileName:'Orders_2021_'})">Text</button>
            <button mat-raised-button (click)="exporter.exportTable('csv',{fileName:'Orders_2021_'})">CSV</button>
        </div>
        </div>  

     <div class="mat-elevation-z8 table-container" >
      <table mat-table matTableExporter [dataSource]="dataSource" #exporter="matTableExporter">
  
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>ID</th>
          <td mat-cell *matCellDef="let element">{{element.id}}</td>
        </ng-container>
        <ng-container matColumnDef="index">
          <th mat-header-cell *matHeaderCellDef>Index</th>
          <td mat-cell *matCellDef="let element; let i = index">{{i+1}}</td>
        </ng-container>
        <ng-container matColumnDef="delivery_status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">{{element.delivery_status}}</td>
        </ng-container>
        <ng-container matColumnDef="biz_name">
          <th mat-header-cell *matHeaderCellDef>Business Name</th>
          <td mat-cell *matCellDef="let element">{{element.biz_name}}</td>
        </ng-container>
        <ng-container matColumnDef="main_recipient">
          <th mat-header-cell *matHeaderCellDef>Customer Name</th>
          <td mat-cell *matCellDef="let element">{{element.main_recipient}}</td>
        </ng-container>
        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef>Address</th>
          <td mat-cell *matCellDef="let element">{{element.address}}</td>
        </ng-container>
        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef>City</th>
          <td mat-cell *matCellDef="let element">{{element.city}}</td>
        </ng-container>
        <ng-container matColumnDef="state">
          <th mat-header-cell *matHeaderCellDef>State</th>
          <td mat-cell *matCellDef="let element">{{element.state}}</td>
        </ng-container>
        <ng-container matColumnDef="zip_code">
          <th mat-header-cell *matHeaderCellDef>Zip Code</th>
          <td mat-cell *matCellDef="let element">{{element.zip_code}}</td>
        </ng-container>
        <ng-container matColumnDef="country">
          <th mat-header-cell *matHeaderCellDef>country</th>
          <td mat-cell *matCellDef="let element">{{element.country}}</td>
        </ng-container>
        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef>Phone</th>
          <td mat-cell *matCellDef="let element">{{element.phone}}</td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element">{{element.email}}</td>
        </ng-container>
        <ng-container matColumnDef="order_number">
          <th mat-header-cell *matHeaderCellDef>Order Number</th>
          <td mat-cell *matCellDef="let element">{{element.order_number}}</td>
        </ng-container>

       
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row ; columns: displayedColumns;"></tr>
        
    </table>

  
    </div>
 
  </main>