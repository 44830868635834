import { Router } from '@angular/router';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Order } from 'src/app/services/dataType';

@Component({
  selector: 'app-dialog-ask-remove-customer',
  templateUrl: './dialog-ask-remove-customer.component.html',
  styleUrls: ['./dialog-ask-remove-customer.component.css']
})
export class DialogAskRemoveCustomerComponent implements OnInit {
  customerID:string;

  constructor(public orders:OrdersService, private router:Router) { }

  removeCustomer(){
    console.log('deleting: ' + this.customerID);
    this.orders.removeCustomer(this.customerID).subscribe((res)=>{console.log(res)});
    this.router.navigate(['/']);

  }

  ngOnInit(): void {
    this.customerID = this.orders.customerFormBuilder.get(['customer_id']).value;
    this.orders.getCustomerByID(this.customerID).subscribe(res => this.orders.customerFormBuilder.patchValue(res[0]));

  }

}
