import { SalesReportComponent } from './components/users/sales-report/sales-report.component';
import { SalesReport } from './services/dataType';
import { FullcustomersearchComponent } from './components/users/fullcustomersearch/fullcustomersearch.component';
import { EditordercustomerComponent } from './components/users/editordercustomer/editordercustomer.component';
import { ListOrdersComponent } from './components/users/list-orders/list-orders.component';
import { AddNewOrderComponent } from './components/users/add-new-order/add-new-order.component';
import { AddNewCustomerComponent } from './components/users/add-new-customer/add-new-customer.component';
import { SiginComponent } from './components/users/sigin/sigin.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListCustomersComponent } from './components/users/list-customers/list-customers.component';

const routes: Routes = [
  {
    path:'',
    component: SiginComponent
  },
  {
    path:'api/authorized/listorders',
    component: ListOrdersComponent
  },
  {
    path:'api/authorized/listcustomers',
    component: ListCustomersComponent
  },
 {
    path:'api/authorized/editordercustomer',
    component: EditordercustomerComponent
  },
  {
    path:'api/authorized/addneworder',
    component: AddNewOrderComponent
  },
  {
    path:'api/authorized/fullsearchcustomer',
    component: FullcustomersearchComponent
  },
  {
    path:'api/authorized/neworders_report',
    component: SalesReportComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
