import { CCard } from './../../../services/dataType';
import { OrdersService } from './../../../services/orders.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { Order } from 'src/app/services/dataType';


@Component({
  selector: 'app-add-new-order',
  templateUrl: './add-new-order.component.html',
  styleUrls: ['./add-new-order.component.css']
})
export class AddNewOrderComponent implements OnInit {
d = new Date();
tempCardNumber:string='Add New Card Number';
tempCardExp:string='Exp';
tempCardCvc:string='CVC';
customerType: string = 'New ';
cards: CCard[] = [];
dataSource: Order[] = [];

// export interface CCard{
//   cardNumber: string,
//   exp: string,
//   cvc: string
// }

  constructor(public orders: OrdersService, private router: Router) { }

  ngOnInit(): void {
    this.orders.getOrdersByCustomerID(this.orders.customerFormBuilder.get(['customer_id']).value).subscribe(res=>{
      if(res.length > 0){
        this.dataSource = res;
        this.customerType =   'ReOrder ';
        this.cards = JSON.parse(this.orders.customerFormBuilder.get(['cards']).value);
        // console.log(res);
        // console.log('LOYAL CUSTOMER, ORDERS ON FILE: ' + res.length);
        // console.log(this.orders.customerFormBuilder.get(['cards']).value);
        // console.log(this.cards);

      }else{
        console.log('NEW CUSTOMERS CLEAN HISTORY')
      }
    });
  }
  addNewCard(newNumber,newExp,newCVC){
    let newCard={
      cardNumber : newNumber.value,
      exp: newExp.value,
      cvc: newCVC.value
    };
    console.log(newCard);
    this.cards.push(newCard);
    newNumber.value='';
    newExp.value='';
    newCVC.value='';
    console.log(this.cards);
    console.log(JSON.stringify(this.cards));
    this.orders.customerFormBuilder.patchValue({cards:JSON.stringify(this.cards)});
  }

  removeCard(index:number){
    this.cards.splice(index,1);
    this.orders.customerFormBuilder.patchValue({cards:JSON.stringify(this.cards)});

  }
  saveCustomer(){
    let loyalCustomer = true;
    this.orders.saveCustomer(loyalCustomer)
    .subscribe(res => {
      console.log(res);
    });

  }

  goBackHome(){
  }

  saveNewOrder(){
    // this.orders.customerOrderForm.patchValue({customer_id: this.orders.customerFormBuilder.get(['customer_id']).value});
    // console.log(this.orders.customerOrderForm.value);
    console.log('Customer Form Saved OK');
    console.log(this.orders.customerOrderForm.value);
    this.orders.addNewOrder().subscribe(res => console.log(res));
    this.router.navigate(['api/authorized/neworders_report']);
  }

}
